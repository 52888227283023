<script setup lang="ts">

</script>

<template>
  <div col items-center pb-62px pt-60px lg:pb-86px sm:pt-120px class="sponsors">
    <slot />

    <p text-20px font-bold uppercase lg:text-40px md:text-30px>
      <span>main</span>
      <span ml-2 class="text-#ce0000">sponsors</span>
    </p>

    <div mt-40px row items-center>
      <img h-30px object-scale-down sm:h-40px src="/images/seer.webp">
      <img ml-40px h-30px object-scale-down sm:h-40px src="/images/w-img-223821.webp">
      <img ml-40px h-40px object-scale-down lg:ml-60px lt-sm:hidden src="/images/image-175.webp">
    </div>
    <img mt-20px h-40px object-scale-down lg:ml-60px sm:hidden src="/images/image-175.webp">

    <!--  -->

    <p mt-40px text-20px font-bold uppercase md:mt-60px lg:text-40px md:text-30px>
      <span>media</span>
      <span ml-2 class="text-#ce0000">partners</span>
    </p>

    <div mt-40px row items-center>
      <img h-25px object-contain sm:h-30px src="/images/image-1401.webp">
      <img ml-40px h-19px object-contain lg:ml-60px sm:h-20px src="/images/image-1402.webp">
    </div>
  </div>
</template>

<style scoped>
.sponsors {
  background: linear-gradient(180deg, #000, #57020c);
}
</style>
